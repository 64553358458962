import React from 'react';
import Layout from '../../components/Layout/layout';
import EmploymentLaw11022022 from '../../Views/Website/Blog/EmploymentLaw11022022/EmploymentLaw11022022';

const EmploymentLaw11022022Page: React.FC = (props: any) => {
  return (
    <Layout>
      <EmploymentLaw11022022 location={props.location.href} />
    </Layout>
  );
};

export default EmploymentLaw11022022Page;
