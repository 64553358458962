import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../../components/Banner/Banner';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import * as styles from '../blogs.module.scss';
import BlogImg from '../../../../assets/IMG/blog/11-02-2022.png';
import ReCAPTCHA from "react-google-recaptcha";

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from "react-share";
import { API_AddToNewsLetter, API_BaseURL } from '../../../../Services/ApiRoutes';
import { Fetch } from '../../../../Services/Fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faSpinner } from '@fortawesome/free-solid-svg-icons';

interface Props {
  location: string;
}

const EmploymentLaw11022022: React.FC<Props> = ({ location }) => {
  const shareURL: string = location ? `${(API_BaseURL === "/api/" && !location.toLowerCase().includes("beta") ? "https://primehr.co.uk/" : "https://beta.primehr.co.uk/")}Blog/Employment-Law-11-02-2022` : "";
  const [email, setEmail] = useState<string>("");
  const [captcha, setCaptcha] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const Join = async () => {
    setLoading(true);

    if (captcha && email) {
      await Fetch(API_AddToNewsLetter, email).then((Success: boolean) => {
        if (Success) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            setEmail("");
          }, 3000);
        }
      })
    }

    setLoading(false);
  }

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: Blog :: Employment Law 11-02-2022</title><meta charSet='utf-8' />
        <meta property="og:description" content="A call centre worker who was sacked for hanging up on almost half the customers who telephoned, has lost their employment tribunal claim, The Times reports." />
        <meta property="og:image" content={BlogImg} />
        <meta property="og:title" content="PrimeHR :: Blog :: Employment Law 11-02-2022" />
        <meta property="og:site_name" content="PrimeHR" />
        <meta property="og:url" content={shareURL} />
      </Helmet>

      <Banner />

      <Breadcrumb Trail={[
        { To: "/Blog", Text: "Blog" },
        { To: "", Text: "Employment Law 11-02-2022" }
      ]} />

      <div className={styles.Section}>
        <div className={styles.SectionInner}>
          <h1>Employment Law - Policy, Case Law & Updates</h1>
          <div className={styles.Social}>
            <FacebookShareButton url={shareURL}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={shareURL}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <LinkedinShareButton url={shareURL}>
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>

            <span>Published On {new Date(2022, 2, 4).toDateString()}</span>
          </div>

          <div className={styles.SectionImage}>
            <img loading={"lazy"} src={BlogImg} />
          </div>

          <h3>Disciplining Staff for Conduct Issues</h3>

          <p>
            A call centre worker who was sacked for hanging up on almost half the customers who telephoned, has lost their employment tribunal claim, The Times reports.
          </p>
          <p>
            It continues:
          </p>
          <p>
            "Muawana McCollin began working as a customer service representative for Telecom Service Centres in May 2018. They were given responsibility for answering customer calls for Sainsbury's.
          </p>
          <p>
            "The following year concerns were raised about their hanging up early on customers.
          </p>
          <p>
            "In the month of June 2019, 62 of their 129 calls were terminated early when customers were upset or unhappy.
          </p>
          <p>
            "Ms McCollin claimed that a medical condition which affected their eyesight meant they would terminate calls in error because they struggled to press the correct button.
          </p>
          <p>
            "The manager, who listened to the calls, said that they could not see any reason for the calls dropping off other than they were trying to avoid taking the full call.
          </p>
          <p>
            "Ms McColin was sacked after being found guilty of gross misconduct for "deliberately and willfully cutting the customer off calls".
          </p>
          <p>
            "They appealed and claimed that medication they were taking made them drowsy so they sometimes could not hear the customer.
          </p>
          <p>
            "Bosses said they had never had a call taker prematurely hang up on a customer due to drowsiness."
          </p>
          <p>
            The tribunal concluded: "We are satisfied that [the employers] had good cause, with overwhelming evidence, to summarily dismiss."
          </p>

          <h3>Plumber wins unfair dismissal and age discrimination claim</h3>

          <p>
            So whats in a nickname?
          </p>
          <p>
            A plumber who was called "Half-Dead Dave" by his colleagues has been awarded <b>£24,926</b> (not including legal fees and the emotional cost which runs into 1000's) compensation in an unfair dismissal and age discrimination claim, BBC News reports.
          </p>
          <p>
            It continues:
          </p>
          <p>
            "David Robson, 69, was sacked from Clarke's Mechanicals Ltd without warning or consultation.
          </p>
          <p>
            "An employment tribunal heard they were given the "cruel nickname" by a manager because of their age, from 2015 to 2020 in a "continuing pattern of misconduct causing detriment".
          </p>
          <p>
            "As a result Mr Robson was left "distressed and embarrassed".
          </p>
          <p>
            "The tribunal held: "It made them the more anxious for their job, in an environment where that conduct went without comment... coming from management as it did, this was not building-site banter"."
          </p>

          <h3>£749.16 Awarded for Unlawful Deduction of Wages</h3>

          <p>
            We ask was it worth the cost?
          </p>
          <p>
            A driver resigned from their current employer and had 4 weeks notice, which they decided they didn't want to serve, the individual then went off sick but agreed with their new employer that they could start work before their notice ended. Their current employer took exception to this and then deducted wages they 'felt' they shouldn't be entitled to receive.
          </p>
          <p>
            Whilst we empathise with the employer this is one of those cases where you have to ask was it worth the effort of defending their actions.
          </p>
          <p>
            The employer decided not to engage an expert and pulled together a 50 page bundle, which distracted them from running their business for over 7 months.
          </p>
          <p>
            It was evident that the employee was 'entitled' to this payment and a quick call to us would have clarified the 'right' action for the employer to take.
          </p>
          <p>
            As an employer tribunal cases are published as a matter of public record, you can't request to have them taken down and the PR can be devastating for some organisations.
          </p>
          <p>
            Don't get caught out you can always give us a call on 01158 372772 without it costing you a penny and we'll tell you if we can help!
          </p>
        </div>
        <div className={styles.SectionSide}>
          <div className={styles.SectionSideInfo}>
            <h4>Keep Up To Date</h4>
            <p>If you find our blog updates useful then consider subscribing to our newsletter for regular updates on UK Employment Law</p>

            <label>
              <span>Your Email:</span>
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email..." />
            </label>

            <div className="capcha">
              <ReCAPTCHA
                onExpired={() => { setCaptcha("") }}
                onErrored={() => { setCaptcha("") }}
                sitekey="6Lf-XiQaAAAAAI5mHpzBUc6vtyvEWTS17PLcGnWT" onChange={e => { setCaptcha(e); }} />
            </div>

            <div className={styles.Download}>
              <button className={success ? styles.Green : ""} disabled={!captcha || !email} type="button" onClick={() => Join()}>{loading ? <><i><FontAwesomeIcon icon={faSpinner} spin={true} /></i> Joining...</> : success ? <><i><FontAwesomeIcon icon={faCheckDouble} /></i> Successfully Joined!</> : "Join Newsletter"}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmploymentLaw11022022;